import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen'
import { AlertController, Platform } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { App as CapacitorApp } from '@capacitor/app';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router:Router,
    private authenticateService:AuthenticationService,
    public alertController: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      CapacitorApp.addListener('backButton', ({canGoBack}) => {
        if(!canGoBack){
          this.showExitConfirm();
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
      this.authenticateService.authenticationState.subscribe(state => {
        if(state) {
          this.router.navigate(['home']);
        } else {
          this.router.navigate(['login']);
        }
      })

    });
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }
}
