import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  { 
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  { 
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
 },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'profile-address',
    loadChildren: () => import('./pages/profile-address/profile-address.module').then( m => m.ProfileAddressPageModule)
  },
  {
    path: 'profile-password',
    loadChildren: () => import('./pages/profile-password/profile-password.module').then( m => m.ProfilePasswordPageModule)
  },
  {
    path: 'visitor',
    loadChildren: () => import('./pages/visitor/visitor.module').then( m => m.VisitorPageModule)
  },
  {
    path: 'request-call',
    loadChildren: () => import('./pages/request-call/request-call.module').then( m => m.RequestCallPageModule)
  },
  {
    path: 'makeapayment',
    loadChildren: () => import('./pages/makeapayment/makeapayment.module').then( m => m.MakeapaymentPageModule)
  },
  {
    path: 'online-pharmacy',
    loadChildren: () => import('./pages/online-pharmacy/online-pharmacy.module').then( m => m.OnlinePharmacyPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
